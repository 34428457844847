import { get, map, replace, toLower } from 'lodash-es'
import { pushDataLayer } from '../index'
import { PageViewLayer } from '@component/dataLayer'
import { joinStr } from '@utils'

export default function PageViewDataLayer({
  data
}) {

  return (
    <PageViewLayer data={{
      'vertical': 'news',
      'vertical_type': get(data, 'category', ''),
      'event': 'page_view',
      'page_type': 'detail',
      'article_slug': get(data, 'slug', ''),
      'sponsor_name': get(data, 'sponsorName', ''),
      'author_name': get(data, 'author', ''),
      'news_category': get(data, 'category', ''),
      'tags': joinStr(map(data.tags, 'label'), ','),
      'article_id': get(data, 'id', '')
    }}></PageViewLayer>
  )
}

export function NewsSearchPageViewDataLayer({
  term,
  count
}) {

  return (
    <>
      <PageViewLayer data={{
        'event': 'ga4_event',
        'event_name': 'perform_search',
        'search_method': 'type_search',
        'search_term': toLower(term),
        'search_result_count': count
      }}></PageViewLayer>
      <PageViewLayer data={{
        'event': 'analytics_track',
        'event_journey': 'Search',
        'event_interaction': 'CTA',
        'event_type': 'news-search',
        'search_term': term,
        'search_result_count': count
      }}></PageViewLayer>
    </>
  )
}

export const articleDataLayer = {
  loginView: {
    show() {
      pushDataLayer({
        'event': 'ga4_event',
        'event_name': 'editorial_article_gate_success',
        'reg_gate_method': 'enter_email'
      })
    },

    success() {
      pushDataLayer({
        'event': 'ga4_event',
        'event_name': 'editorial_article_gate'
      })
    },

    signin() {
      pushDataLayer({
        'event': 'analytics_track',
        'event_journey': 'Estimates lead capture',
        'event_interaction': 'Estimates listings page',
        'event_type': 'Sign in'
      })
    }
  },

  featuredPropertyClick(items) {

    pushDataLayer({
      'ecommerce': null
    })

    pushDataLayer({
      'event': 'view_item_list',
      'ecommerce': { items }
    })
  },

  showCategory() {
    pushDataLayer({
      'event': 'ga4_event',
      'event_name': 'click_open_nav_bar'
    })
  },

  categoryClick(data) {
    if (!data) return

    pushDataLayer({
      'event': 'ga4_event',
      'event_name': `click_${toLower(replace(data.name, /[^a-zA-Z]+/g, '_'))}`
    })
  }
}