'use client'

import useProgressRouter from '@hooks/useProgressRouter'
import { articleDataLayer } from '@plugin/google/gtm/modules/news'
import { getRouterHref, URLS } from '@router'
import { news } from '@store/index'
import { usePathname } from 'next/navigation'
import { useState } from 'react'
import { useSetRecoilState } from 'recoil'

export default function SearchBar() {

  const setNewsState = useSetRecoilState(news)
  function showCategory() {
    articleDataLayer.showCategory()
    setNewsState(d => ({ ...d, showFiexdCategory: true }))
  }

  const [keywords, setKeywords] = useState('')
  const pathname = usePathname()
  const { replaceRouter, pushRouter } = useProgressRouter()
  function submitHandle(event) {
    event.preventDefault()

    if (!keywords) return
    const url = getRouterHref(URLS.newsSearchList, { keywords })
    if (pathname.startsWith(url)) {
      replaceRouter(url)
    } else {
      pushRouter(url)
    }
  }

  return (
    <div className='section-wrap' id="news-search-bar">
      <div className='border flex items-center justify-between px-14 h-44 sm:h-56 sm:px-34 sm:mt-14'>
        <button type='button' className='space-x-10 flex items-center cursor-pointer' onClick={showCategory} aria-label='show category'>
          <i className='icon icon-plus-square !text-xl'></i>
          <span className='text-font text-sm'>Select news section to browse</span>
        </button>
        <form className='w-256 h-32 bg-neutral-1 rounded-sm items-center pl-12 hidden sm:flex' onSubmit={submitHandle}>
          <input type='text' placeholder='Search' className='bg-transparent text-sm h-full flex-1' autoFocus value={keywords} onChange={e => setKeywords(e.target.value)} />
          <button type='submit' className='px-12' aria-label='search news'>
            <i className='icon icon-search text-black'></i>
          </button>
        </form>
      </div>
    </div>
  )
}