'use client'

import NewsNormal, { SkeletonCard } from '@component/card/news/normal'
import Pagination from '@component/pagination'
import useFetch from '@hooks/useFetch'
import { hubpageDetail } from '@router'
import { assign, map, range } from 'lodash-es'
import { usePathname, useSearchParams, useRouter } from 'next/navigation'
import { useCallback, useMemo, useState } from 'react'
import { buildUrl } from '@plugin/http/lib/utils'

export default function NewsListContent({
  list,
  pageSize,
  totals,
  url,
  params,
  keywords,
  isCarousel = false
}) {

  const searchParams = useSearchParams()
  const dftPage = 1
  const [pageIndex, setPageIndex] = useState(parseInt(searchParams.get('page') || 1))
  const isDftPage = pageIndex === dftPage
  const { data, isLoading } = useFetch(
    isDftPage
      ? null
      : { url, params: assign({}, params, { offset: (parseInt(pageIndex) - 1) * pageSize, limit: pageSize }) }
  )
  const viewData = isDftPage ? list : data

  const pathname = usePathname()
  const route = useRouter()
  const changeHandle = useCallback(idx => {
    // 这里如果是从hubpage过来，点击第一页，需要返回到hubpage页面
    if (isCarousel && idx === 1) {
      route.push(hubpageDetail({ hubType: searchParams.get('type'), slug: keywords.replace(/-\d+$/, '') }))
      return
    }
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setPageIndex(idx)
    route.replace(buildUrl(pathname, undefined, idx > 1 ? { page: idx } : undefined))
  }, [isCarousel, keywords, setPageIndex, route, pathname, searchParams])

  const pageUrl = useMemo(() => {
    return `${pathname}?page={page}`
  }, [pathname])

  return (
    <>
      <div className='grid grid-cols-1 gap-x-24 gap-y-50 mt-16 sm:grid-cols-2 lg:grid-cols-3'>
        {
          isLoading && (
            map(range(0, 6), idx => <SkeletonCard key={idx}></SkeletonCard>)
          )
        }
        {
          !isLoading && viewData && (
            map(viewData, item => (
              <NewsNormal data={item} key={item.id}></NewsNormal>
            ))
          )
        }
      </div>
      {
        totals > pageSize && (
          <Pagination className='mt-26' url={pageUrl} right pageIndex={pageIndex} pageSize={pageSize} totals={totals} onChange={changeHandle}></Pagination>
        )
      }
    </>
  )
}